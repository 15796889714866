<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="4">
            <e-store-combo
              v-model="filters.storeId"
              :required="false"
              :placeholder="$t('Todas')"
            />
          </b-col>
          <b-col md="4">
            <e-checking-account-combo
              v-model="filters.checkingAccountId"
              type="Main"
              :store-id="filters.storeId"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="status"
              v-model="filters.status"
              :placeholder="$t('Todos')"
              type="vue-select"
              :label="$t('Status')"
              :options="statusReconciliationOptions"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="movementDate"
              v-model="filters.movementDate"
              type="date-range-picker"
              :label="$t('Período de movimento')"
              :time-picker="false"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="reconciliations-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('RECONCILIATION.NAME'), 'female')"
        :fields="fields"
        :items="reconciliations"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <e-grid-actions
            :show-update="$can('Update', 'GetnetReconciliation')"
            :show-delete="false"
            @update="onUpdate(row)"
          />
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="reconciliations-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <fab
      v-if="$can('Create', 'GetnetReconciliation')"
      :main-tooltip="$t('Adicionar Conciliação')"
      @click="onCreateReconciliation"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EStoreCombo,
  EFilters,
  EGridActions,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { reconciliationDomains, statusTypes } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import ECheckingAccountCombo from '@/views/components/inputs/ECheckingAccountCombo.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPageSizeSelector,
    EPaginationSummary,
    fab,
    EFilters,
    EGridActions,
    EStoreCombo,
    ECheckingAccountCombo,
  },

  mixins: [statusTypes, reconciliationDomains],

  data() {
    return {
      fetching: false,
      edit: false,
      saving: false,
    }
  },

  computed: {
    ...mapState('pages/financial/reconciliation/getnet', [
      'reconciliations',
      'paging',
      'sorting',
      'filters',
    ]),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('#'),
          key: 'id',
          tdClass: 'text-center',
          sortable: true,
          thStyle: { width: '80px' },
        },
        {
          label: this.$t('Loja'),
          key: 'store.id',
          tdClass: 'text-left',
          formatter: (val, index, item) =>
            this.$options.filters.storeName(item.checkingAccount.store),
          sortable: true,
        },
        {
          label: this.$t('Conta Corrente'),
          key: 'checkingAccount.name',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Data Movimento'),
          key: 'movementDate',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => this.$options.filters.date(value),
          sortable: true,
        },
        {
          label: this.$t('% Concluído'),
          key: 'percentCompleted',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          formatter: value => this.$options.filters.percentage(value / 100),
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'status',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => this.$t(`Reconciliation_${value}` || '-'),
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/financial/reconciliation/getnet/maintain', {
      cleanState: 'cleanState',
      maintainResetFilters: 'resetFilters',
    }),
    ...mapActions('pages/financial/reconciliation/getnet', [
      'setFilter',
      'setCurrentPage',
      'setPageSize',
      'fetchReconciliations',
      'resetFilters',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchReconciliations()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onCreateReconciliation() {
      this.cleanState()
      this.$router.push({ name: 'reconciliation-getnet-create' })
    },

    async onUpdate(row) {
      const { id } = row.item
      this.maintainResetFilters()
      this.$router.push({ name: 'reconciliation-getnet-maintain', params: { id } })
    },
  },
}
</script>

<style></style>
